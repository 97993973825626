import React from 'react';
//import logo from './logo.svg';
import './App.css';
import RSVP from './components/RSVP';
import VideoBackground from './components/videoBackground';

const App: React.FC = () => {

  return (
    <div className="App" style={{ padding: "20" }}>
      <VideoBackground />
      <RSVP />
    </div>
  );
}

export default App;
