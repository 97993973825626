import React, { useState } from 'react';
import { Guest, plusOneGuest } from './types';
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Container,
    Paper,
    Typography,
    ToggleButtonGroup,
    ToggleButton,
    Autocomplete,
} from '@mui/material';
import beetleIcon from '../assets/BeatleMers.gif';

const inviteeOptions = [
    'Fierascu, Daniel',
    'Mitroi, Marin',
    'Popescu, Ion',
    'Ionita, Anca-Violeta'
];

const RSVPForm: React.FC = () => {
    const [formData, setFormData] = useState<Guest>({
        name: '',
        plusOne: false,
        plusOneGuest: undefined,
        children: 0,
        phoneNo: '',
        attending: false,
        hotel: false,
        menu: 'Vegetarian',
    });
    const [participation, setParticipation] = useState<boolean>(false);
    const [plusOneDisplay, setPlusOneDisplay] = useState<boolean>(false);

    // Handles input changes for text fields, checkboxes, switches, etc.
    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | { name?: string; value: unknown }>
    ) => {
        const { name, value, type } = e.target as HTMLInputElement;
        const checked = (e.target as HTMLInputElement).checked;

        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    // Handles changes for ToggleButtonGroup (e.g., hotel room request).
    const handleHotelToggleChange = (event: React.MouseEvent<HTMLElement>, value: string) => {
        setFormData({
            ...formData,
            hotel: value === 'Da',
        });
    };

    const handleAttendingToggleChange = (event: React.MouseEvent<HTMLElement>, value: string) => {
        setFormData({
            ...formData,
            attending: value === 'Da',
        });
        setParticipation(value === 'Da');
    };

    // Handles changes for PlusOneGuest details.
    const handlePlusOneGuestChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | { name?: string; value: unknown }>
    ) => {
        const { name, value } = e.target as HTMLInputElement;

        setFormData({
            ...formData,
            plusOneGuest: {
                ...formData.plusOneGuest,
                [name!]: value,
            } as plusOneGuest,
        });
    };

    // Handles toggling the PlusOne option.
    const handlePlusOneToggleChange = (event: React.MouseEvent<HTMLElement>, value: string) => {
        const isPlusOne = value === 'Da';
        setFormData({
            ...formData,
            plusOne: isPlusOne,
            plusOneGuest: isPlusOne
                ? {
                    name: '',
                    menu: 'Standard',
                }
                : undefined,
        });
        setPlusOneDisplay(isPlusOne);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        console.log(formData);
        alert('RSVP submitted. Thank you!');
    };

    return (
        <Container maxWidth="sm" sx={{ backgroundSize: 'cover', padding: 4, borderRadius: 4 }}>
            <Paper elevation={24} sx={{ marginBottom: 10, padding: 6, backgroundColor: '#ffecd1', borderRadius: 4, border: '2px dashed #ff6f61' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 4 }}>
                    <img src={beetleIcon} alt="RSVP Icon" style={{ width: 60, height: 50, marginRight: 10, marginBottom:0 }} />
                    <Typography variant="h4" component="h2" sx={{ fontFamily: 'Comic Sans MS', color: '#ff6f61', textShadow: '2px 2px #ffa726', marginBottom:2 }}>
                        Confirmare Participare
                    </Typography>
                </Box>
                <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                    <Autocomplete
                        id="name"
                        options={inviteeOptions}
                        value={formData.name}
                        onChange={(event, newValue) => {
                            setFormData({ ...formData, name: newValue || '' });
                        }}
                        onInputChange={(event, newInputValue) => {
                            setFormData({ ...formData, name: newInputValue });
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Nume"
                                variant="outlined"
                                required
                                InputProps={{ ...params.InputProps, style: { fontFamily: 'Comic Sans MS' } }}
                                sx={{ backgroundColor: '#fff3e0', borderRadius: 2 }}
                            />
                        )}
                    />

                    <TextField
                        id="phoneNo"
                        name="phoneNo"
                        label="Numar de telefon"
                        variant="outlined"
                        value={formData.phoneNo}
                        onChange={handleInputChange}
                        required
                        InputProps={{ style: { fontFamily: 'Comic Sans MS' } }}
                        sx={{ backgroundColor: '#fff3e0', borderRadius: 2 }}
                    />

                    <Typography variant='h6' align='left' sx={{ fontFamily: 'Comic Sans MS', color: '#ff6f61', textShadow: '1px 1px #ffa726' }}>Vei participa?</Typography>
                    <ToggleButtonGroup
                        exclusive
                        value={formData.attending ? 'Da' : 'Nu'}
                        onChange={handleAttendingToggleChange}
                        aria-label="Will you attend?"
                        sx={{ alignSelf: 'center' }}
                    >
                        <ToggleButton value="Da" aria-label="Da" sx={{ backgroundColor: formData.attending ? '#ff6f61' : '#fff3e0', borderRadius: 4, fontFamily: 'Comic Sans MS', color: 'black', border: formData.attending ? '3px solid #ff6f61' : '3px solid #ccc' }}>
                            <Typography sx={{ fontFamily: 'Comic Sans MS' }}>Da</Typography>
                        </ToggleButton>
                        <ToggleButton value="Nu" aria-label="Nu" sx={{ backgroundColor: !formData.attending ? '#ff6f61' : '#fff3e0', borderRadius: 4, fontFamily: 'Comic Sans MS', color: 'black', border: !formData.attending ? '3px solid #ff6f61' : '3px solid #ccc' }}>
                            <Typography sx={{ fontFamily: 'Comic Sans MS' }}>Nu</Typography>
                        </ToggleButton>
                    </ToggleButtonGroup>

                    {participation && (
                        <>
                            <FormControl variant="outlined" sx={{ backgroundColor: '#fff3e0', borderRadius: 2, padding: 1 }}>
                                <InputLabel id="menu-label" sx={{ fontFamily: 'Comic Sans MS' }}>Tip de meniu</InputLabel>
                                <Select
                                    labelId="menu-label"
                                    id="menu"
                                    name="menu"
                                    value={formData.menu}
                                    onChange={(e) =>
                                        handleInputChange(e as React.ChangeEvent<{ name?: string; value: unknown }>)
                                    }
                                    label="Menu Choice"
                                    sx={{ fontFamily: 'Comic Sans MS' }}
                                >
                                    <MenuItem value="Standard">Standard</MenuItem>
                                    <MenuItem value="Vegetarian">Vegetarian</MenuItem>
                                </Select>
                            </FormControl>

                            <Typography variant='h6' align='left' sx={{ fontFamily: 'Comic Sans MS', color: '#ff6f61', textShadow: '1px 1px #ffa726' }}>Vei veni insotit?</Typography>
                            <ToggleButtonGroup
                                exclusive
                                value={formData.plusOne ? 'Da' : 'Nu'}
                                onChange={handlePlusOneToggleChange}
                                aria-label='Plus One'
                                sx={{ alignSelf: 'center' }}
                            >
                                <ToggleButton value="Da" aria-label="Da" sx={{ backgroundColor: formData.plusOne ? '#ff6f61' : '#fff3e0', borderRadius: 4, fontFamily: 'Comic Sans MS', color: 'black', border: formData.plusOne ? '3px solid #ff6f61' : '3px solid #ccc' }}>
                                    <Typography sx={{ fontFamily: 'Comic Sans MS' }}>Da</Typography>
                                </ToggleButton>
                                <ToggleButton value="Nu" aria-label="Nu" sx={{ backgroundColor: !formData.plusOne ? '#ff6f61' : '#fff3e0', borderRadius: 4, fontFamily: 'Comic Sans MS', color: 'black', border: !formData.plusOne ? '3px solid #ff6f61' : '3px solid #ccc' }}>
                                    <Typography sx={{ fontFamily: 'Comic Sans MS' }}>Nu</Typography>
                                </ToggleButton>
                            </ToggleButtonGroup>


                            {plusOneDisplay && (
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                                    <TextField
                                        id="plusOneGuestName"
                                        name="name"
                                        label="Nume Invitat"
                                        variant="outlined"
                                        value={formData.plusOneGuest?.name}
                                        onChange={handlePlusOneGuestChange}
                                        required
                                        InputProps={{ style: { fontFamily: 'Comic Sans MS' } }}
                                        sx={{ backgroundColor: '#fff3e0', borderRadius: 2 }}
                                    />

                                    <FormControl variant="outlined" sx={{ backgroundColor: '#fff3e0', borderRadius: 2, padding: 1 }}>
                                        <InputLabel id="plusOneMenu-label" sx={{ fontFamily: 'Comic Sans MS' }}>Meniu Invitat</InputLabel>
                                        <Select
                                            labelId="plusOneMenu-label"
                                            id="plusOneMenu"
                                            name="menu"
                                            value={formData.plusOneGuest?.menu}
                                            onChange={(e) =>
                                                handlePlusOneGuestChange(e as React.ChangeEvent<{ name?: string; value: unknown }>)
                                            }
                                            label="Menu Choice"
                                            sx={{ fontFamily: 'Comic Sans MS' }}
                                        >
                                            <MenuItem value="Standard">Standard</MenuItem>
                                            <MenuItem value="Vegetarian">Vegetarian</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <TextField
                                        id="children"
                                        name="children"
                                        label="Număr copii"
                                        type="number"
                                        variant="outlined"
                                        value={formData.children}
                                        onChange={handleInputChange}
                                        InputProps={{ style: { fontFamily: 'Comic Sans MS' } }}
                                        sx={{ backgroundColor: '#fff3e0', borderRadius: 2 }}
                                    />
                                </Box>
                            )}

                            <Typography variant='h6' align='left' sx={{ fontFamily: 'Comic Sans MS', color: '#ff6f61', textShadow: '1px 1px #ffa726' }}>Ai nevoie de o cameră la hotel?</Typography>
                            <ToggleButtonGroup
                                exclusive
                                value={formData.hotel ? 'Da' : 'Nu'}
                                onChange={handleHotelToggleChange}
                                aria-label="Need a hotel room?"
                                sx={{ alignSelf: 'center' }}
                            >
                                <ToggleButton value="Da" aria-label="Da" sx={{ backgroundColor: formData.hotel ? '#ff6f61' : '#fff3e0', borderRadius: 4, fontFamily: 'Comic Sans MS', color: 'black', border: formData.hotel ? '3px solid #ff6f61' : '3px solid #ccc' }}>
                                    <Typography sx={{ fontFamily: 'Comic Sans MS' }}>Da</Typography>
                                </ToggleButton>
                                <ToggleButton value="Nu" aria-label="Nu" sx={{ backgroundColor: !formData.hotel ? '#ff6f61' : '#fff3e0', borderRadius: 4, fontFamily: 'Comic Sans MS', color: 'black', border: !formData.hotel ? '3px solid #ff6f61' : '3px solid #ccc' }}>
                                    <Typography sx={{ fontFamily: 'Comic Sans MS' }}>Nu</Typography>
                                </ToggleButton>
                            </ToggleButtonGroup>


                        </>
                    )}

                    <Button type="submit" variant="contained" color="primary" sx={{ fontFamily: 'Comic Sans MS', padding: 2, backgroundColor: '#ff6f61', alignSelf: 'center', borderRadius: 8, boxShadow: '4px 4px #ffa726' }}>
                        Trimite Confirmarea
                    </Button>
                </Box>
            </Paper>
        </Container>
    );
};

export default RSVPForm;
